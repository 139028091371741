<template>
    <div class="eco-content-space h100 overflow-y-auto">
        <div class="white-box">
            <div class="table-top d-block">
                <b-row>
                    <b-col cols="12">
                        <div class="table-top-buttons">
                            <button-field
                                v-if="false"
                                @click="addNDocument()"
                                          icon="plus"
                                          color="primary">{{$ml.get('ADD_DOCUMENT')}}
                            </button-field>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="eco-content-scroll d-flex flex-wrap gap-2 pt-4">
                <b-card v-for="cat in categories" :key="cat.slug" class="help-docs-cat-list">
                    <h3 class="mb-3">{{cat.label}}</h3>
                    <div v-if="sortedDocs[cat.slug] && sortedDocs[cat.slug].length"  class="items-list">
                        <div v-for="(document,index) in sortedDocs[cat.slug]" :key="document.id" class="doc-item d-flex justify-content-start w-100 mb-3 pb-2"
                            :class=" index != sortedDocs[cat.slug].length-1 ? `border-bottom` : ``"
                        >
                            <a :href="document.attributes.attachment._url" target="_blank" class="mr-2 w-25 doc-img-link">
                                <b-img :src="document.attributes.coverImage._url" width="80" height="80" fluid class="min-width-80"/>
                            </a>
                            <div class="doc-meta">
                                <a :href="document.attributes.attachment._url" target="_blank" class="d-inline pb-0 mb-0">
                                    <span class="label d-block">{{document.attributes.name}}</span>
                                </a>
                                <span class="subtitle d-block font-small-2 text-muted">{{document.attributes.subtitle}}</span>

                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <p class="text-muted">No documents available in this category.</p>
                    </div>
                </b-card>

            </div>
        </div>
    </div>
</template>

<script>

import Parse from 'parse';

export default {
    name: "KnowledgeCenter",
    data() {
        return {
            categories: [
                {slug: 'admin_panel', label: 'Working with the Admin Panel'},
                {slug: 'setup', label: 'Setup Qfecard on mobile device'},
                {slug: 'features', label: 'Features'},
            ],
            sortedDocs: {}, // Initialize as an object
        };
    },
    methods: {
        async fetchData() {
            try {
                const docQuery = new Parse.Query('Tutorial');
                docQuery.include('category'); // Ensure category is included if it's a pointer
                const docs = await docQuery.find();

                // Group documents by category slug and sort each group by 'order'
                const grouped = this.categories.reduce((acc, cat) => {
                    acc[cat.slug] = docs
                        .filter(doc => doc.get('category') === cat.slug)
                        .sort((a, b) => a.get('order') - b.get('order'));
                    return acc;
                }, {});

                this.sortedDocs = grouped;
                console.log(this.sortedDocs);
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
        },
        addNDocument() {
            this.$router.push({ name: 'add-document' });
        },
    },
    mounted() {
        this.fetchData();
    },
};

</script>


<style scoped>
.help-docs-cat-list {
    width: calc(50% - 1rem);
}

.gap-2 {
    gap: 2rem;
}

.doc-img-link {
    max-width: 100px;
}

.min-width-80 {
    min-width: 80px;
    min-height: 80px;
}


</style>
